@media screen and (max-height: 550px) {
    html {
        body {
            min-height: 540px;
            #root {
                // 修复web端ctrl放大没有滚动条
                overflow: auto;
                .appLayout {
                    min-height: 540px;
                    // 修复web端ctrl放大没有滚动条
                    min-width: 960px;
                    .leftSide {
                        & > div:first-child {
                            display: flex;
                            flex-direction: column;
                            overflow: hidden;
                            .focus-nav {
                                overflow: auto;
                            }
                        }
                    }
                    .rightSide {
                        .appContent {
                            .calendar-page-container {
                                min-height: 500px;
                            }
                        }
                    }
                }
            }
            .ant-modal-wrap.modalUserSelection {
                .ant-modal {
                    overflow: hidden;
                    .ant-modal-content {
                        height: 100%;
                        overflow: hidden;
                        .ant-modal-body {
                            & > div:first-child {
                                height: 240px !important;
                            }
                        }
                    }
                }
            }
            .ant-modal {
                // .chat-uploadimg {
                //     padding-top: 10px;
                //     padding-bottom: 10px;
                //     .img-container {
                //         width: 200px;
                //         height: 200px;
                //         line-height: 200px;
                //         img {
                //             max-width: 200px;
                //             max-height: 200px;
                //         }
                //     }
                // }
            }

            .ant-modal-wrap.base-modal-wrapper.change-calendar-modal {
                .participants-list {
                    max-height: 121px !important;
                }
            }
        }
    }
}
