p,
div {
    margin-top: 0;
    margin-bottom: 0;
}
.df {
    display: flex;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.inline-block {
    display: inline-block;
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.two-lines {
    display: -webkit-box;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.dd-emoji {
    // width: 20px;
    width: 24px;
    margin: 0 2px;
    vertical-align: text-bottom;
}

.ant-btn-primary {
    background: @primary-color !important;
    border-color: @primary-color !important;
    outline: none !important;
    border-radius: 4px;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    color: #ffffff;
    opacity: 0.5;
}

.dangerous-inner-html {
    display: flex;
    align-items: center;
    height: 100%;
}

.e-mention-user {
    color: @primary-color !important;
}

input,
select,
option,
textarea :hover {
    box-shadow: none;
    outline: none;
}
.ant-input:focus {
    border-color: none !important;
    box-shadow: none;
}

.spin-container {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.appContent {
    position: relative;
}
.primary-color {
    color: @primary-color;
}
.primary-bg {
    background-color: @primary-color;
}

.global-upload-controller {
    z-index: 999;
    position: fixed;
    right: 40px;
    bottom: 40px;
    width: 440px;
    max-height: 336px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.08);
}

.me-avatar {
    background-color: @primary-color !important;
    color: #fff;
}
.userSelectionModalLeft .ant-tabs-tab-active {
    color: @primary-color !important;
}
.userSelectionModalLeft .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: @primary-color !important;
}
.userSelectionModalLeft .ant-tabs-nav .ant-tabs-nav-list button{
    &:hover {
        color: @primary-color !important;
    }
}
.userSelection-search-container .cursor-outline:hover{ 
    border-color: @primary-color !important;  
}
.search-reacord-input-container-input:hover{ 
    border-color: @primary-color !important;  
}
.address-main .address-org .addressListContent .addressListBody-list .crumb-container .crumb-item .orgBreadCrumbI.active{
    color: @primary-color !important;
}
.focus-user-selection-org-nav-item > div .user-selection-item-text.active{
    color: @primary-color !important;
}
.focus-user-selection-dept-avatar{
    background-color: @primary-color !important;
}

.global-search-input:hover{
    border-color: @primary-color !important;  
}
.search-global-translateY .global-search-input:hover{ 
    border-color: transparent !important;
}

.feedbackModal .feedback-con .feedback-header .header-tabs .header-tab.selected {
    color: @primary-color !important;  
}

.group-members .group-in .members_head .ant-input-affix-wrapper:hover{
    border-color: @primary-color !important; 
}

// .search-global-translateY .global-search-input{
//     &:hover{
//         border-color: @primary-color !important;
//     }
// }


.create-schedule-drawer-wrapper .ant-input:hover{
    border-color: @primary-color !important;
}