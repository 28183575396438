@import '~antd/lib/style/themes/default.less';
@import '~@jd/focus-desktop-comps/lib/less/variable.less';
@import '../../../../../assets/themes/default';

@app-bg: #232930;

//@primary-color: #31c4cc; // 全局主色
//@primary-background-color: rgba(49, 196, 204, 0.13);

@second-color: #eaf9f9; // 次色

//@primary-color: #306fdc; // 全局主色
//@primary-background-color: rgba(48, 111, 220, 0.13);

@grey-1: #232930;
@grey-2: #62656d;
@grey-3: #8f959e; // 非主要信息引导、及常规按钮边框等
@grey-4: #bfc1c4; // 导航栏默认状态内容用色等
@gray-5: #efefef; // 边框颜色
@gray-6: #a0a5aa;
@gray-7: #f0f3f3;
@grey-8: #f2f4f5; // 消息框

@white: #ffffff;

@background-1: #31c4cc19; // 用于导航栏背景
@background-2: #f8f9fa; // 用于cell底色背景，页面背景色
@background-3: #f8f8f8; // 日程附件底色

@text-color: #232930; // 文字默认色
@text-gray: #c5c5c5;

@primary-hover-tint: #58d7da; // hover 浅色，用于重要的文字、按钮和icon的hover等
@primary-hover-shade: #1f9ba5; // hover 深色，用于重要的文字、按钮和icon的hover等
@primary-hover-listitem: #ebf9fa; // list item hover

@left-bar-bg: #3771bb1a;

@link-color: #4c7cff; // 链接色

@info-color: #ffb416; // 询问色
@success-color: #2ece39; // 成功色
@warning-color: #f96137; // 警告色
@warning-color-10: rgba(249, 97, 55, 0.1);

@danger-color: #f96137; // 危险色

@tip-color: #de2418;

@text-color: @grey-1; // 主文本色 主要内容用色，用于常规标题、文字浏览、常规按钮文字及图标引导等
@text-color-secondary: @grey-2; // 次文本色 次要内容用色，用于次级标题内容、属性标示等

@text-color-secondary-grey: #666666; // 次文本色 次要内容用色，用于次级标题内容、属性标示等

@divider-shade: #e8ebec; // 页面布局分割线、buuton间隔线
@divider-tint: #f0f3f3; // 常用列表分割线
@hover: #f6f6f6;
@hover-light: #f8f9f9;

@font-size-base: 14px; // 主字号

@item-select-color: rgba(48, 111, 220, 0.05);

@font-size-16: 16px;
@font-size-18: 18px;

@border-radius-base: 4px; // 组件/浮层圆角

@popover-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);

@gray-color: @grey-3;
@gray-light-color: @background-2;
@split-line-color: @divider-shade;
@hover-color: @hover;


.layoutCenterLeft() {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.widthHeight100() {
    width: 100%;
    height: 100%;
}

.centered() {
    display: flex;
    align-items: center;
    justify-content: center;
}

.borderBottom(@color: @divider-tint) {
    border-bottom: 1px solid @color;
}
.borderRight(@color: @divider-tint) {
    border-right: 1px solid @color;
}

.bottomSplitLine(@color: @split-line-color) {
    content: '';
    position: absolute;
    height: 1px;
    background: @color;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.topSplitLine() {
    content: '';
    position: absolute;
    // height: 1px;
    // background: #D6D8DB;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-top: 1px solid #D6D8DB;
}

@left-side-color: @app-bg;
//var(--left-side-color);
@top-side-color: @app-bg;
//var(--top-side-color);
@primary-color: #bd1818; // 全局主色
@secondary-color: #fbf6f6;
@hover-color: rgba(189, 24, 24, 0.1);
@active-color: rgba(189, 24, 24, 0.1);
@secondary-color-for_task: #bd1818;
@hover-color-for_task: #bd1818;
@active-color-for_task: #9d0000;
@active-border-color: #bd1818;
@success-color: #00a645;
@fail-color: #ed6a0c;
@form-elem-bgcolor2: #f6f6f6;
@form-elem-bgcolor: #f8f8f8;
@form-elem-candidate: #62656d;
@form-elem-placeholder: #bfc1c4;
@divider-line-color: #e8ebec;
@primary-background-color: rgba(48, 111, 220, 0.13);
@primary-background-color-2: rgba(189, 24, 24, 0.1);
@left-side-primary-background-color: #bd1818; // unselected part of left side bar background color
@left-side-primary-background-color-selected: #9d0000; // selected part of left side bar background color
@left-side-selected-color: #ffffff; // select part of left side bar color
@left-side-unselected-color: rgba(255, 255, 255, 0.5); // select part of left side bar color
@topBar-color: #f6f6f6;
@drawer-color: #fff;
// @text-color: #232930;
@grey: rgba(222, 224, 227, 0.2);
@primary: #fff;
@main-color: #051b4a;
@button-color: #bd1818;
@white-color: #ffffff;
@usercar-color: #bd1818;
@white-color-opacity: rgba(255, 255, 255, 0.5);
@collect-selected: #e9c73a;
@tick-icon-color: #335475;
@hover-text: #000;
@dept-hover: #bd1818;
@hover: #f6f6f6;
@userCard-hover: #bd1818;
@avatar-color: #bd1818;
@left-side-primary-color: @white-color-opacity;
@draggable-color: @left-side-unselected-color;

@link-color: #bd1818; // 链接色
@border-color: #ebeeee; // 工作台卡片边框
@third-bg: #fbf6f6; //工作台卡片背景色

:export {
    primaryColor: @primary-color;
}
.search-task-item {
    display: flex;
    padding: 10px 24px;
    cursor: pointer;

    &:hover {
        background-color: #f6f6f6;
    }

    .detail {
        color: #000;
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 2;
    }

    .time {
        color: #717171;

        &.overdue {
            color: #F68C72;

            span {
                display: inline-block;
                margin-left: 5px;
                padding: 0 5px;
                border: 1px solid rgba(255, 73, 73, 0.46);
                border-radius: 3px;
            }
        }
    }
}
