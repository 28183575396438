.arrow-icon() {
    .anticon.lb-conclude {
        color: #8f959e;

        svg {
            position: relative;
            top: 50%;
            margin-top: -50%;
        }
    }
}

.common-show-of-task() {
    .ant-collapse {
        background: none;
        border: 0;
        width: 100%;

        .ant-collapse-item {
            border: none;

            .ant-collapse-header {
                padding: 0;

                .label {
                    padding-left: 0;

                    b {
                        font-weight: 500;
                    }
                }

                .ant-collapse-arrow {
                    right: 8px;
                }

                [role='img'] {
                    color: #8f959e;
                }

                &>[role='img'] {
                    position: absolute;
                    right: 8px;
                }

                em {
                    color: @secondary-color-for_task;
                    display: inline-block;
                    font-style: normal;
                    margin-left: 4px;
                    margin-right: 12px;
                }
            }

            .ant-collapse-content-box {
                padding: 0;
            }
        }
    }

    .content-part-other {
        position: relative;
        padding: 10px 8px;
        border-top: 1px solid transparent;
        box-sizing: content-box;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            display: block;
            width: calc(100% - 16px);
            height: 1px;
            background-color: @divider-line-color;
        }

        .task-mynote-item {
            margin: 0;

            .label {
                em {
                    font-size: 14px;
                    color: rgba(143, 149, 158, 1);
                    font-style: normal;
                }
            }
        }
    }
}

.advance-record-of-task() {
    .advance-record-wrapper {
        // padding: 0 24px 24px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .advance-record {
            font-size: 12px;
            margin-bottom: 10px;
            margin-right: 0;

            .ant-steps.lb-decorate {
                padding-left: 48px;

                .ant-steps-item-tail {
                    top: 10px;
                    left: -9px;
                    margin: 0;
                    padding: 3px 0;
                    height: 100%;
                }

                .ant-steps-item-content .ant-steps-item-title {
                    font-weight: normal;
                    color: #232930;
                    display: inline-flex;
                    justify-content: space-between;

                    .ant-steps-item-subtitle {
                        color: #8F959E;
                    }
                }

                .ant-steps-item-icon {
                    .ant-steps-icon {
                        display: block;
                    }

                    .lt-whole-progress {
                        position: absolute;
                        left: -48px;
                        top: -4px;
                        font-size: 12px;
                        font-weight: normal;
                        line-height: normal;
                        color: #62656D;
                    }

                    .ant-steps-icon-dot {
                        width: 7px;
                        height: 7px;
                        background: none;
                        border: 1px solid rgba(221, 221, 221, 1);
                    }
                }

                .ant-steps-item-finish {
                    .ant-steps-item-icon {

                        .ant-steps-icon-dot {
                            background: @primary-color;
                            border: none;
                            box-shadow: 0 0 10px 0 @primary-color;
                        }
                    }

                    .lt-whole-progress,
                    .ant-steps-item-content .ant-steps-item-title .user-select-text {
                        color: @primary-color;

                        div {
                            display: inline-flex;
                            align-items: center;
                        }
                    }
                }
            }

            .ant-steps-item-container {
                display: flex;

                .ant-steps-item-content {
                    flex: 1;

                    .ant-steps-item-title {
                        width: 100%;
                        margin-top: 2px;
                        font-size: 12px;
                        color: #62656d;
                        padding-right: 0;

                        >div:first-child {
                            display: inline-block;
                            max-width: calc(100% - 120px);
                            font-weight: normal;
                            white-space: break-spaces;
                            line-height: 1.4;

                            .priority-img {
                                width: 10px;
                                height: 10px;
                                margin-left: 5px;
                                vertical-align: initial;
                            }
                        }

                        .ant-steps-item-subtitle {
                            float: right;
                            font-size: 12px;
                            color: #bbbbbbff;
                        }
                    }

                    .ant-steps-item-description {
                        font-size: 12px;
                        color: #666666ff;
                        padding-right: 16px;
                        padding-top: 10px;
                    }
                }
            }

            .more {
                text-align: center;
                overflow: hidden;
            }
        }

        .as-to-task {
            .scroll-view-content {
                margin-right: 0 !important;
            }
        }
    }
}

.task-ui-common() {
    .ant-btn {
        padding: 0 24px;
        height: 32px;
    }

    .ant-input {
        box-sizing: border-box;
        border: 1px solid transparent;
        background-color: @form-elem-bgcolor;
        border-radius: 3px;

        &:hover {
            border-color: @hover-color-for_task;
        }

        &:focus {
            border-color: @active-border-color;
            background-color: #fff;
        }
    }
}

.title-container {
    padding: 0 8px;
}

// 统一的确认框
.task-common-modal-lb {
    &.ant-modal-confirm {
        position: absolute;
        top: 0;
        padding: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        display: inline-flex;
    }

    .ant-modal-content {
        width: 360px;
        margin: auto;

        .ant-modal-body {
            padding: 16px 24px 0;
            font-size: 14px;
            line-height: 1.5715;
            word-wrap: break-word;

            .ant-modal-confirm-body {
                .anticon {
                    font-size: 20px;
                    margin-right: 8px;
                }

                .ant-modal-confirm-title {
                    line-height: 20px;
                }

                .ant-modal-confirm-content {
                    margin: 0;
                    margin-top: 16px;
                    width: 100%;
                    padding: 24px;
                    box-sizing: content-box;
                    margin-left: -24px;
                    border-top: 1px solid #E8EBEC;
                    text-align: center;

                    span {
                        text-align: left;
                        display: inline-block;
                    }

                }
            }

            .ant-modal-confirm-btns {
                float: initial;
                border-top: 1px solid #E8EBEC;
                margin: 0;
                width: 100%;
                padding: 10px 24px;
                box-sizing: content-box;
                margin-left: -24px;
                text-align: right;
            }

        }
    }
}

// 临时删除

.action-button-zan {
    opacity: 0;
}

.my-progress-header {
    p {}
}