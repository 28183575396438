.ant-dropdown {
    &-menu{
       &-item{
           &:hover{
            background: #F0F3F3;
           }
       }
    }
}

