.viewer {
    &-zoom-in,
    &-zoom-out,
    &-one-to-one,
    &-reset,
    &-prev,
    &-play,
    &-next,
    &-rotate-left,
    &-rotate-right,
    &-flip-horizontal,
    &-flip-vertical,
    &-fullscreen,
    &-fullscreen-exit,
    &-close {
        &::before {
            background-image: url('./icons.png');
            background-repeat: no-repeat;
            background-size: 280px;
            color: transparent;
            display: block;
            font-size: 0;
            height: 20px;
            line-height: 0;
            width: 20px;
        }
    }
    &-download::before {
        background-image: url('./download.png');
        background-repeat: no-repeat;
        background-position: 3px 4px;
        background-size: 14px 12px;
        color: transparent;
        display: block;
        font-size: 0;
        height: 20px;
        line-height: 0;
        width: 20px;
        content: 'Download';
    }
    &-prevself::before {
        background-image: url('./icons.png');
        background-repeat: no-repeat;
        background-size: 280px;
        color: transparent;
        display: block;
        font-size: 0;
        height: 20px;
        line-height: 0;
        width: 20px;
        background-position: -80px 0;
        content: 'Previous';
    }
    &-nextself::before {
        background-image: url('./icons.png');
        background-repeat: no-repeat;
        background-size: 280px;
        color: transparent;
        display: block;
        font-size: 0;
        height: 20px;
        line-height: 0;
        width: 20px;
        background-position: -120px 0;
        content: 'Next';
    }

    &-download:hover::after {
        content: '下载';
    }
    &-prevself:hover::after {
        content: '上一张';
    }
    &-nextself:hover::after {
        content: '下一张';
    }

    &-zoom-in::before {
        background-position: 0 0;
        content: 'Zoom In';
    }

    &-zoom-in:hover::after {
        content: '放大';
    }

    &-zoom-out::before {
        background-position: -20px 0;
        content: 'Zoom Out';
    }

    &-zoom-out:hover::after {
        content: '缩小';
    }

    &-one-to-one::before {
        background-position: -40px 0;
        content: 'One to One';
    }

    &-one-to-one:hover::after {
        content: '图片原始大小';
    }

    &-one-to-one {
        &.unClickable:hover::after {
            content: '自适应图片大小';
        }
    }

    &-prevself {
        &.unClickable {
            pointer-events: none;
            &:hover::after {
                content: '上一张';
            }
        }
    }

    &-nextself {
        &.unClickable {
            pointer-events: none;
            &:hover::after {
                content: '下一张';
            }
        }
    }

    &-reset::before {
        background-position: -60px 0;
        content: 'Reset';
    }

    &-prev::before {
        background-position: -80px 0;
        content: 'Previous';
    }

    &-prev:hover::after {
        content: '上一张';
    }

    &-play::before {
        background-position: -100px 0;
        content: 'Play';
    }

    &-next::before {
        background-position: -120px 0;
        content: 'Next';
    }

    &-next:hover::after {
        content: '下一张';
    }

    &-rotate-left::before {
        background-position: -140px 0;
        content: 'Rotate Left';
    }

    &-rotate-right::before {
        // background-position: -160px 0;
        background-image: url('./rotate-right.png');
        background-repeat: no-repeat;
        background-position: 3px 4px;
        background-size: 14px 12px;
        color: transparent;
        display: block;
        font-size: 0;
        height: 20px;
        line-height: 0;
        width: 20px;
        transform: rotate(90deg);
        content: 'Rotate Right';
    }

    &-rotate-right:hover::after {
        content: '旋转';
    }

    &-flip-horizontal::before {
        background-position: -180px 0;
        content: 'Flip Horizontal';
    }

    &-flip-vertical::before {
        background-position: -200px 0;
        content: 'Flip Vertical';
    }

    &-fullscreen::before {
        background-position: -220px 0;
        content: 'Enter Full Screen';
    }

    &-fullscreen-exit::before {
        background-position: -240px 0;
        content: 'Exit Full Screen';
    }

    &-close::before {
        background-position: -260px 0;
        content: 'Close';
    }

    &-container {
        bottom: 0;
        direction: ltr;
        font-size: 0;
        left: 0;
        line-height: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        -webkit-tap-highlight-color: transparent;
        top: 50px;
        touch-action: none;
        -webkit-touch-callout: none;
        user-select: none;

        &::selection,
        & *::selection {
            background-color: transparent;
        }

        &:focus {
            outline: 0;
        }

        & img {
            display: block;
            height: auto;
            max-height: none !important;
            max-width: none !important;
            min-height: 0 !important;
            min-width: 0 !important;
            width: 100%;
        }
    }

    &-canvas {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: -50px;

        & > img {
            height: auto;
            margin: 15px auto;
            max-width: 90% !important;
            width: auto;
        }
    }

    &-footer {
        bottom: 0;
        left: 0;
        // overflow: hidden;
        position: absolute;
        right: 0;
        text-align: center;
    }

    &-navbar {
        background-color: rgba(0, 0, 0, 0.5);
        overflow: hidden;
    }

    &-list {
        box-sizing: content-box;
        height: 50px;
        margin: 0;
        overflow: hidden;
        padding: 1px 0;

        & > li {
            color: transparent;
            cursor: pointer;
            float: left;
            font-size: 0;
            height: 50px;
            line-height: 0;
            opacity: 0.5;
            overflow: hidden;
            transition: opacity 0.15s;
            width: 30px;

            &:focus,
            &:hover {
                opacity: 0.75;
            }

            &:focus {
                outline: 0;
            }

            & + li {
                margin-left: 1px;
            }
        }

        & > .viewer-loading {
            position: relative;

            &::after {
                border-width: 2px;
                height: 20px;
                margin-left: -10px;
                margin-top: -10px;
                width: 20px;
            }
        }

        & > .viewer-active {
            &,
            &:focus,
            &:hover {
                opacity: 1;
            }
        }
    }

    &-player {
        background-color: #000;
        bottom: 0;
        cursor: none;
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        & > img {
            left: 0;
            position: absolute;
            top: 0;
        }
    }

    &-toolbar {
        & > ul {
            display: inline-block;
            margin: 0 auto 5px;
            overflow: hidden;
            padding: 6px 3px;

            & > li {
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 50%;
                cursor: pointer;
                float: left;
                height: 24px;
                overflow: hidden;
                transition: background-color 0.15s;
                width: 24px;
                // &.unClickable {
                //     pointer-events: none;
                //     opacity: 0.7;
                // }
                &:focus,
                &:hover {
                    background-color: rgba(0, 0, 0, 0.8);
                    &::after {
                        position: absolute;
                        top: -15px;
                        font-size: 12px;
                        padding: 8px 5px;
                        border: 1px solid #606060;
                        background: #ffffff;
                    }
                }

                &:focus {
                    box-shadow: 0 0 3px #fff;
                    outline: 0;
                    position: relative;
                    z-index: 1;
                }

                &::before {
                    margin: 2px;
                }

                & + li {
                    margin-left: 1px;
                }
            }

            & > .viewer-small {
                height: 18px;
                margin-bottom: 3px;
                margin-top: 3px;
                width: 18px;

                &::before {
                    margin: -1px;
                }
            }

            & > .viewer-large {
                height: 30px;
                margin-bottom: -3px;
                margin-top: -3px;
                width: 30px;

                &::before {
                    margin: 5px;
                }
            }
        }
    }

    &-tooltip {
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 10px;
        color: #fff;
        display: none;
        font-size: 12px;
        height: 20px;
        left: 50%;
        line-height: 20px;
        margin-left: -25px;
        margin-top: -10px;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 50px;
    }

    &-title {
        color: #ccc;
        display: inline-block;
        font-size: 12px;
        line-height: 1;
        margin: 0 5% 5px;
        max-width: 90%;
        opacity: 0.8;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: opacity 0.15s;
        white-space: nowrap;

        &:hover {
            opacity: 1;
        }
    }

    &-button {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        cursor: pointer;
        height: 80px;
        overflow: hidden;
        position: absolute;
        right: -40px;
        top: -40px;
        transition: background-color 0.15s;
        width: 80px;

        &:focus,
        &:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }

        &:focus {
            box-shadow: 0 0 3px #fff;
            outline: 0;
        }

        &::before {
            bottom: 15px;
            left: 15px;
            position: absolute;
        }
    }

    &-fixed {
        position: fixed;
    }

    &-open {
        overflow: hidden;
    }

    &-show {
        display: block;
    }

    &-hide {
        display: none;
    }

    &-backdrop {
        // background-color: rgba(0, 0, 0, 0.5);
    }

    &-invisible {
        visibility: hidden;
    }

    &-move {
        cursor: move;
        cursor: grab;
    }

    &-fade {
        opacity: 0;
    }

    &-in {
        opacity: 1;
    }

    &-transition {
        transition: all 0.3s;
    }

    @keyframes viewer-spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &-loading {
        &::after {
            animation: viewer-spinner 1s linear infinite;
            border: 4px solid rgba(255, 255, 255, 0.1);
            border-left-color: rgba(255, 255, 255, 0.5);
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: 40px;
            left: 50%;
            margin-left: -20px;
            margin-top: -20px;
            position: absolute;
            top: 50%;
            width: 40px;
            z-index: 1;
        }
    }

    @media (max-width: 767px) {
        &-hide-xs-down {
            display: none;
        }
    }

    @media (max-width: 991px) {
        &-hide-sm-down {
            display: none;
        }
    }

    @media (max-width: 1199px) {
        &-hide-md-down {
            display: none;
        }
    }
}
