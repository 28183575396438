@import '@/assets/less/variable.less';
/* 所有公共依赖在此再次定义，保证随时从当前仓库分离 */

.pm-layer {
    position: absolute;
    z-index: 999;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 2px 12px 0px rgba(56, 62, 68, 0.31);
    padding: 5px 0;
    min-width: 80px;
    font-size: 14px;
}

.ant-btn {
    box-shadow: none;
}
