.community-aikf {
    .chat-container {
        .main {
            min-width: inherit;
        }
    }
    .input-control-container {
        min-width: initial;
    }
}
