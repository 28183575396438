// @import "../antd-custom.less";
// @import "components/base.less";
// @import "components/layout.less";
// @import "components/button.less";
// @import "components/header.less";
// @import "components/footer.less";
// @import "components/panel.less";
// @import "components/form.less";
// @import "components/modal.less";
// @import "components/content.less";
// @import "components/card.less";
@import 'components/dropdown.less';
// @import "components/breadcrumb.less";
// @import "components/tab.less";
// @import "components/page.less";
// @import "components/popover.less";
// @import "components/mask.less";
// @import "components/timline.less";
// @import "components/table.less";
// @import "components/icon.less";
// @import "~antd/dist/antd.less";
@import '../../../node_modules/@jd/focus-desktop-comps/lib/less/index.less';
// @import "~@jd/focus-desktop-comps/lib/less/variable.less";
// @import "./variable.less";
@import './common.less';
@import 'components/hide.less';
@import './min.height.less';
@import 'components/custom.less';
//@import "./default.theme.less";
.focusListItem .item-content-desc {
    line-height: 14px !important;
}
.crumb-dept-user-container .focus-user-selection-org-nav-item {
    line-height: 14px !important;
}

.userSelectionModalLeft .data-list-container {
    overflow-y: auto;
    overflow-y: overlay;
}

.userSelection-search-container .search-content-wrap,
.userSelection-search-container
    .userSelection-search-content
    > .ant-tabs
    > .ant-tabs-content-holder {
    overflow-y: auto;
    overflow-y: overlay;
}
.userSelectionModalRight .selected-container {
    overflow-y: auto;
    overflow-y: overlay;
}
